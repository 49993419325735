import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { comparacaoString } from "../funcoes"
import { Input, Col, Label, FormGroup, FormFeedback } from "reactstrap"

/*****************************************************************************************************************************************/
//Componente responsavel por configurar um input do reactstrap de acordo com a necessidade de gerar formulario dinamicos para supervisor//
/***************************************************************************************************************************************/

const InputForm = (props) => {
    const [patternValidacao, setPattern] = useState({ mensagem: "", correto: false });
    //const [messagemErro, setMessagemErro] = useState({ mensagem: "", correto: 'okay' });
    const [manipuladoPeloUsuario, setManipuladoPeloUsuario] = useState(false);

    const isValid = () => (manipuladoPeloUsuario || props.submit) && (props.invalido || patternValidacao.correto);

    const changeClass = () => isValid() ? " input-invalid is-invalid " : "";

    const validarOnChange = (name, value) => {
        if (props.pattern) {
            const reg = new RegExp(props.pattern);
            const resultado = reg.test(value);
            if (resultado) {
                props.onChange(name, value, props.indice);
            } else {
                props.onChange(name, "");
            }
            setPattern({ correto: !resultado, mensagem: "Campo não está no padrão.." });
            //setMessagemErro({ correto: !resultado});

        } else if (props.comparar) {
            const { operador, valor, campo, mensagem = "Campo não está no padrão.." } = props.comparar;
            let resultado = false;
            if (campo) {
                resultado = props.valoresAtuais[campo] ? comparacaoString[operador](props.valoresAtuais[campo], value) : true;
            } else {
                resultado = comparacaoString[operador](valor, value);
            }

            if (resultado) {
                props.onChange(name, value, props.indice);
            } else {
                props.onChange(name, "");
            }
            setPattern({ correto: !resultado, mensagem });
        } else {
            props.onChange(name, value, props.indice);
        }
    }

    const formatarValor = (event) => {

        if (props.mask) {
            if (Array.isArray(props.mask)) {
                validarOnChange(event.target.name, event.target.value);
            } else {
                let value = event.target.value.slice(2);
                value = value.slice(-1) === ',' ? value.slice(0, -2) : value;
                //value = value.replace(/\.|\,/g, (x) => (x === '.' ? '' : '.'));
                value = value.replace(' ', '');
                validarOnChange(event.target.name, value);
            }
        } else {
            const valor = event.target.type !== 'checkbox' ? event.target.value : event.target.checked;
            validarOnChange(event.target.name, valor);
        }
    }

    const gerarMask = () => {
        if (props.mask) {
            if (Array.isArray(props.mask)) {
                return props.mask;
            } else {
                return createNumberMask(props.mask);
            }
        } else {
            return undefined;
        }
    }

    const gerarValorPadrao = () => {
        if (props.mask && props.valorPadrao && !Array.isArray(props.mask)) {
            return props.valorPadrao.replace(/\.|\,/g, (x) => (x === '.') ? ',' : '.');
        } else {
            return props.valorPadrao;
        }
    }

    return (
        <Col {...props.style.col} className="form-col">
            <FormGroup className={(props.hidden ? "hidden" : "")}>
                {props.label && (
                    <Label className={props.style.labelClass + " label"} >
                        {props.label + ":"}
                    </Label>
                )}
                <Input
                    // isRequired={props.required}
                    type={props.type}
                    innerRef={props.innerRef}
                    placeholder={props.placeholder}
                    defaultValue={gerarValorPadrao()}
                    value={props.value}
                    checked={props.checked}
                    name={props.name}
                    disabled={props.disabled}
                    onChange={formatarValor}
                    onBlur={() => setManipuladoPeloUsuario(true)}
                    className={props.style.elementoClass + changeClass()}
                    readOnly={props.readOnly}
                    maxLength={props.maxLength}
                    min={props.min}
                    max={props.max}
                    mask={gerarMask()}
                    tag={props.mask ? MaskedInput : undefined}

                />
                <FormFeedback >
                    {patternValidacao.correto ? patternValidacao.mensagem : props.mensagem ?? "Digite uma opção valida!"}
                    {/* {messagemErro.correto ? messagemErro.mensagem: "   Já cadastrado existe na base de dados"} */}
                </FormFeedback>
            </FormGroup>
        </Col>
    )
}

const colShape = {
    xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    xl: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

const styleShape = {
    labelClass: PropTypes.string,
    elementoClass: PropTypes.string,
    col: PropTypes.shape(colShape)
}

InputForm.defaultProps = {
    placeholder: "",
    mask: undefined,
    checked: false,
    label: null,
    style: {
        labelClass: "label-conteudoInferior",
        elementoClass: "",
        col: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12
        }

    }
};

InputForm.propTypes = {
    label: PropTypes.string,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    style: PropTypes.shape(styleShape)
}

export default InputForm;
